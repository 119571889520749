import React, { useEffect } from "react";
import Banner from "../AboutUs/Banner/Banner";
import BImg from "./IMG/Banner.png";
import Main from "./IMG/Normal Banner/wordprees.png";
import "./Services.css";
// GIF
import SGIF1 from "./IMG/Animated GIF/WordPress1.gif";
import SGIF2 from "./IMG/Animated GIF/WordPress2.gif";
import SGIF3 from "./IMG/Animated GIF/WordPress3.gif";
import Line from "./IMG/UIUX/Grupline.svg";
import UIUX from "./IMG/Animated SVG/svg animation wordpress development.svg";
//Components
import Portfolio from "../Portfolio/Portfolio";
import Testimonials from "../Home/Testimonials/Testimonials";
import Quate from "../AboutUs/Quote/Quote";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

function WordPress() {
  const navigate = useNavigate();
  const GetInTouch = () => {
    navigate("/ContactUs");
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Banner BannerName="WordPress Development" BannerImg={BImg} />
      <div className="Ser-Con">
        <h1 className="Main-write">
          Empowering Your Digital Presence with Expert
          <span style={{ color: "#EB6F20" }}> WordPress Development </span>
          Solutions.
        </h1>
        <div className="Stage-Compo">
          <div className="Satge-content">
            <p className="line-stages">
              Fueling Digital Growth with Professional WordPress Development
            </p>
            <p className="lining-stageing">
              WordPress development serves as the cornerstone of digital
              transformation, facilitating seamless online experiences through
              innovative website solutions. Our WordPress development company
              excels in harnessing the full potential of this platform to create
              dynamic, user-centric websites. With our expertise, your digital
              footprint is destined to leave a lasting impression on your
              audience, enabling your business to thrive in the digital era.
            </p>
          </div>
          <div className="Stage-Img">
            <img className="Main-Uiux" src={Main} alt="" />
          </div>
        </div>
        <div className="Cards-main">
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF1} alt="" />
            <p className="Text-vuy">
              Revolutionize Your Website's Look and Functionality
            </p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              Unleash the Power of WordPress Theme Customization
            </p>
          </div>
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF2} alt="" />
            <p className="Text-vuy">Empower Your Website</p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              A Deep Dive into WordPress Plugin Development for Enhanced
              Functionality and User Experience
            </p>
          </div>
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF3} alt="" />
            <p className="Text-vuy">Unlocking Ecommerce Excellence</p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              A Comprehensive Guide to Implementing Payment Gateways for a
              Secure and Smooth Shopping Experience
            </p>
          </div>
        </div>
        <h1 className="Acomplesh">
          Crafting Success Stories through Expert
          <span style={{ color: "#EB6F20" }}> WordPress Development</span>.
        </h1>
        <div className="proficient">
          <div className="profic">
            <img className="Uxdjw" src={UIUX} alt="" />
          </div>
          <div className="profic">
            <h2 className="Texticjh">
              Unlock Success with Professional
              <span style={{ color: "#EB6F20" }}> WordPress Development </span>
              Solutions.
            </h2>
            <p className="Textarea918">
              Elevate your online presence and user engagement with our expert
              WordPress development services. We specialize in crafting dynamic
              and user-centric websites that deliver seamless functionality.
            </p>
            <p className="Textarea918">
              Our WordPress development strategies are data-driven, ensuring
              that your online platform aligns perfectly with your goals. With
              our expertise, your WordPress website will be a powerful tool for
              achieving your online objectives.
            </p>
            <button className="Ubtn" onClick={GetInTouch}>
              Get In Touch
            </button>
          </div>
        </div>
      </div>
      <Portfolio />
      <Testimonials />
      <Quate />
      <Footer />
    </>
  );
}

export default WordPress;
