import React from "react";
import "./Portfolio.css";
import Slider from "./Slider/PSlider";

function Portfolio() {
  return (
    <>
      <div className="Portfolio-Main">
        <div className="P-Boundry">
          <div className="text-content">
            <p className="Classical">Portfolio</p>
            <h1 className="Head-type">
              Some of our <span style={{ color: "#EB6F20" }}>works</span>
            </h1>
          </div>
          <div className="Slider-Main">
            <Slider />
          </div>
        </div>
      </div>
    </>
  );
}

export default Portfolio;
