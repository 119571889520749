import React from "react";
import "./slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";
import Slider1 from "../IMG/Slide1.svg";
import Slider2 from "../IMG/Slide2.svg";
import Slider3 from "../IMG/Slide3.svg";
import Slider4 from "../IMG/Slide4.svg";
import { useNavigate } from "react-router-dom";

function PSlider() {
  const navigate = useNavigate();

  const GV = () => {
    navigate("/GujaratVandan");
  };

  const Secret17 = () => {
    navigate("/Secret17");
  };

  const Resources = () => {
    navigate("/QuarecResources");
  };

  const MCXControl = () => {
    navigate("/MCXControl");
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={10}
        slidesPerView={5.5}
        loop={true}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
        breakpoints={{
          1300: {
            slidesPerView: 3.5,
          },
          700: {
            slidesPerView: 2.5,
          },
          500: {
            slidesPerView: 2,
          },
          310: {
            slidesPerView: 2,
          }
        }}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <SwiperSlide>
          <div className="Vnjf02346">
            <div className="jsg0972876" onClick={GV}>
              <img className="SLiDeR1" src={Slider1} alt="" />
              <p className="Card-Slider">Web Development</p>
              <h1 className="Main-CSlider">Gujarat Vandan</h1>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="Vnjf02346">
            <div className="jsg0972876" onClick={Secret17}>
              <img className="SLiDeR1" src={Slider2} alt="" />
              <p className="Card-Slider">Web Development</p>
              <h1 className="Main-CSlider">Secret17</h1>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="Vnjf02346">
            <div className="jsg0972876" onClick={Resources}>
              <img className="SLiDeR1" src={Slider3} alt="" />
              <p className="Card-Slider">Web Development</p>
              <h1 className="Main-CSlider">Quarec Resources</h1>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="Vnjf02346" onClick={MCXControl}>
            <div className="jsg0972876">
              <img className="SLiDeR1" src={Slider4} alt="" />
              <p className="Card-Slider">Wordpress Development</p>
              <h1 className="Main-CSlider">MCX Control</h1>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default PSlider;
