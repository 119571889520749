import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Footer.css";
import { Icon } from "@iconify/react";
import sendIcon from "@iconify/icons-fa/send";

function NewsLatter() {
  const [email, setEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setFormError(true);
      setFormSubmitted(false);
      return;
    }
    const formData = new FormData();
    formData.append("email", email);

    try {
      const response = await fetch(
        "https://quarecres.mcxcontrol.com/api/it_subscribers.php",
        {
          method: "POST",
          body: formData,
        }
      );
      console.log("Response Status:", response.status);
      const contentType = response.headers.get("content-type");
      let data;

      if (contentType && contentType.includes("application/json")) {
        data = await response.json();
      } else {
        data = await response.text();
      }
      console.log("Response Data:", data);

      if (response.ok) {
        if (data.success) {
          setFormSubmitted(true);
          setFormError(false);
          toast.success("Form submitted successfully!");
        } else {
          setFormSubmitted(false);
          setFormError(true);
          toast.error("Error submitting form.");
        }
      } else {
        setFormSubmitted(false);
        setFormError(true);
        toast.error("Error submitting222 form.");
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      setFormSubmitted(false);
      setFormError(true);
      toast.success("Form submitted successfully!");
    }
  };

  return (
    <>
      <form className="News-letter" onSubmit={handleSubmit}>
        <input
          className="Email-input"
          type="email"
          placeholder="Your mail address"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="SetBtn" type="submit">
          <Icon className="Icon-btn" icon={sendIcon} />
        </button>
      </form>
    </>
  );
}

export default NewsLatter;
