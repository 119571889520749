import React from "react";
import "./Quote.css";
import { useNavigate } from "react-router-dom";

function Quote() {
  const navigate = useNavigate();
  const GetInTouch = () => {
    navigate("/ContactUs");
  };

  return (
    <>
      <div className="Quote-Main">
        <div className="Block-Quote">
          <div className="Quote-Content">
            <p className="Setup-text">
              Looking for the Best IT Business Solutions?
            </p>
            <p className="Setup-SubContant">
              As a app web crawler expert, We will help to organize.
            </p>
          </div>
          <div className="Quote-btn">
            {/* <button className="Quan-De-b" onClick={GetInTouch}>
              Get a quote
            </button> */}
            <div style={{ float: "left" }}>
              <div className="centerer1">
                <div className="new-class-name">
                  <a
                    className="btn-61"
                    // onClick={UIUX}
                    // onMouseEnter={handleMouseEnter}
                    // onMouseOut={handleMouseOut}
                  >
                    Get a quote
                    <span></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Quote;
