import React from "react";
import "./Banner.css";
import Benvid from "./Benner.mp4";
import Agency from "./IMG/Agency.svg";
import { Icon } from "@iconify/react";
import stat3 from "@iconify/icons-material-symbols/stat-3";
import flag from "./IMG/Flag.gif";
import Button from "./Button/Button";
// import Steps from "./IMG/Steps.svg";
import Steps from "./Steps/WorkingSteps";
// Services Items
import Service1 from "./IMG/Services-items/Service1.gif";
import Service2 from "./IMG/Services-items/Service2.gif";
import Service3 from "./IMG/Services-items/Service3.gif";
import Service4 from "./IMG/Services-items/Service4.gif";
import Service5 from "./IMG/Services-items/Service5.gif";
import Service6 from "./IMG/Services-items/Service6.gif";
// Services Items
import { useNavigate } from "react-router-dom";

function Banner() {
  const navigate = useNavigate();

  const UIUX = () => {
    navigate("/Ui-Ux");
  };
  const Marketing = () => {
    navigate("/Marketing");
  };
  const Graphic = () => {
    navigate("/Graphic");
  };
  const Devlopment = () => {
    navigate("/Devlopment");
  };
  const Hardware = () => {
    navigate("/Hardware");
  };
  const WordPress = () => {
    navigate("/WordPress");
  };
  const GetInTouch = () => {
    navigate("/ContactUs");
  };
  return (
    <>
      <div className="BannerVid">
        <video className="videoTag" autoPlay loop muted>
          <source src={Benvid} type="video/mp4" />
        </video>
      </div>
      <div className="Main-Container">
        <div className="Card">
          <div className="Cardsetups">
            <div className="IconsImg1"></div>
            <p className="Card-text">Best UI/UX Design Service</p>
            <p className="Card-Subtext">
              Meticulous wireframing, prototyping, and research for intuitive,
              visually stunning interfaces.
            </p>
            <p className="Read-more" onClick={UIUX}>
              Read more
              <Icon className="ico3" icon={stat3} rotate={1} />
            </p>
          </div>
        </div>
        <div className="Card">
          <div className="Cardsetups">
            <div className="IconsImg2"></div>
            <p className="Card-text">Excellent SEO Service For Your Business</p>
            <p className="Card-Subtext">
              Unlock online success with our expert SEO optimization strategies.
            </p>
            <p className="Read-more" onClick={Marketing}>
              Read more
              <Icon className="ico3" icon={stat3} rotate={1} />
            </p>
          </div>
        </div>
        <div className="Card">
          <div className="Cardsetups">
            <div className="IconsImg3"></div>
            <p className="Card-text">World Class Graphic Design Solution</p>
            <p className="Card-Subtext">
              Captivating graphic design services to enhance your brand
              identity.
            </p>
            <p className="Read-more" onClick={Graphic}>
              Read more
              <Icon className="ico3" icon={stat3} rotate={1} />
            </p>
          </div>
        </div>
        <div className="Card">
          <div className="Cardsetups">
            <div className="IconsImg4"></div>
            <p className="Card-text">Fully Responsive Web & App Development</p>
            <p className="Card-Subtext">
              Expert team crafts captivating, responsive websites for
              exceptional user experiences.
            </p>
            <p className="Read-more" onClick={Devlopment}>
              Read more
              <Icon className="ico3" icon={stat3} rotate={1} />
            </p>
          </div>
        </div>
      </div>
      {/* The Best */}
      <div className="Main-Agency">
        <div className="Agency-img">
          <img className="Work-men" src={Agency} alt="" />
        </div>
        <div className="Agency-content">
          <p className="Agency-text">
            <img className="flag-animation" src={flag} alt="" />
            &nbsp; We are <span style={{ color: "#eb6f20" }}> Ordinary </span>
            IT solutions agency in ahmedabad !
          </p>
          <p className="Main-textas">
            Choose <span style={{ color: "#eb6f20" }}> The Best </span> IT
            Service Company in the City.
          </p>
          <Button />
          {/* <button className="Get-Btn" onClick={GetInTouch}>
            Get In Touch
          </button> */}
        </div>
      </div>
      {/* <Steps /> */}
      <div className="Service-main">
        <h1 className="Service-text">Our Feachered Services</h1>
        <p className="Service-Subbloder">
          We provide some <span style={{ color: "#EB6F20" }}>exclusive</span>
          <br /> services for clients
        </p>
        <div className="mainblock-service">
          <div className="block-container">
            <dir className="Divided-block">
              <div className="block-gif">
                <img className="gif-ser" src={Service1} alt="" />
              </div>
              <div className="Block-Containt">
                <h2 className="text-main-ser">UI/UX Design</h2>
                <p className="subtext-main-ser">
                  Designing engaging, user-centric interfaces with wireframing,
                  prototyping, and research.
                </p>
                <p className="Discover-More" onClick={UIUX}>
                  Discover more&nbsp;
                  <Icon className="icoDes" icon={stat3} rotate={1} />
                </p>
              </div>
            </dir>
          </div>
          <div className="block-container">
            <dir className="Divided-block">
              <div className="block-gif">
                <img className="gif-ser" src={Service2} alt="" />
              </div>
              <div className="Block-Containt">
                <h2 className="text-main-ser">SEO & Marketing</h2>
                <p className="subtext-main-ser">
                  Strategic online promotion for business growth and audience
                  engagement.
                </p>
                <p className="Discover-More" onClick={Marketing}>
                  Discover more&nbsp;
                  <Icon className="icoDes" icon={stat3} rotate={1} />
                </p>
              </div>
            </dir>
          </div>
          <div className="block-container">
            <dir className="Divided-block">
              <div className="block-gif">
                <img className="gif-ser" src={Service3} alt="" />
              </div>
              <div className="Block-Containt">
                <h2 className="text-main-ser">Graphic Design</h2>
                <p className="subtext-main-ser">
                  Creating impactful visuals with creativity, precision, and
                  aesthetic finesse.
                </p>
                <p className="Discover-More" onClick={Graphic}>
                  Discover more&nbsp;
                  <Icon className="icoDes" icon={stat3} rotate={1} />
                </p>
              </div>
            </dir>
          </div>
          <div className="block-container">
            <dir className="Divided-block">
              <div className="block-gif">
                <img className="gif-ser" src={Service4} alt="" />
              </div>
              <div className="Block-Containt">
                <h2 className="text-main-ser">Web / App Development</h2>
                <p className="subtext-main-ser">
                  Creating responsive websites and apps with cutting-edge design
                  and functionality.
                </p>
                <p className="Discover-More" onClick={Devlopment}>
                  Discover more&nbsp;
                  <Icon className="icoDes" icon={stat3} rotate={1} />
                </p>
              </div>
            </dir>
          </div>
          <div className="block-container">
            <dir className="Divided-block">
              <div className="block-gif">
                <img className="gif-ser" src={Service5} alt="" />
              </div>
              <div className="Block-Containt">
                <h2 className="text-main-ser">WordPress Development</h2>
                <p className="subtext-main-ser">
                  Creating dynamic websites using powerful, versatile WordPress
                  platform.
                </p>
                <p className="Discover-More" onClick={WordPress}>
                  Discover more&nbsp;
                  <Icon className="icoDes" icon={stat3} rotate={1} />
                </p>
              </div>
            </dir>
          </div>
          <div className="block-container">
            <dir className="Divided-block">
              <div className="block-gif">
                <img className="gif-ser" src={Service6} alt="" />
              </div>
              <div className="Block-Containt">
                <h2 className="text-main-ser">Hardware Solutions</h2>
                <p className="subtext-main-ser">
                  Innovative hardware solutions for diverse technological
                  challenges.
                </p>
                <p className="Discover-More" onClick={Hardware}>
                  Discover more&nbsp;
                  <Icon className="icoDes" icon={stat3} rotate={1} />
                </p>
              </div>
            </dir>
          </div>
        </div>
      </div>
    </>
  );
}
export default Banner;
