import React, { useEffect } from "react";
import Banner from "./Banner/Banner";
import AboutImg from "./IMG/AboutUs.png";
import TheBest from "./IMG/TheBest.svg";
import "./AboutUs.css";
import Line from "./IMG/Line.svg";
import Mission from "./IMG/Mission.gif";
import Vision from "./IMG/Vision.gif";
import Values from "./IMG/Values.gif";
import WorkingStep from "../Home/Banner/Steps/WorkingSteps";
import Directors from "./OurDirectors/Directors";
import Counter from "./Counter/Counter";
import Testimonials from "../Home/Testimonials/Testimonials";
import Quote from "../AboutUs/Quote/Quote";
import Footer from "../Footer/Footer";

function AboutUs() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Banner BannerName="About Us" BannerImg={AboutImg} />
      <div className="AB-Container">
        <div className="Context-AB">
          <p className="text-AB">About Us</p>
          <h2 className="TextM-AB">
            Choose <span style={{ color: "#EB6F20" }}>The Best</span> IT Service
            Company
          </h2>
          <p className="Subtext-AB">
            At Quarec IT Solutions, we are your trusted technology partner. With
            expertise in web development, app development, digital marketing,
            graphic designing, and hardware solutions, we provide comprehensive
            IT services to fuel your business growth. Let us empower your
            organisation with innovative solutions tailored to your unique
            needs.
          </p>
        </div>
        <div className="Context-AB">
          <img className="The-Best" src={TheBest} alt="" />
        </div>
      </div>
      <h2 className="MMV-head">
        Our <span style={{ color: "#EB6F20" }}>Mission</span>,
        <span style={{ color: "#EB6F20" }}>Vision</span> &
        <span style={{ color: "#EB6F20" }}>Values</span>.
      </h2>
      <div className="MVV">
        <div className="MVV-Conteainer">
          <img className="MMV-GIF" src={Mission} alt="" />
          <p className="Big-text-MMV">
            Our <span style={{ color: "#EB6F20" }}>Mission</span>
          </p>
          <img className="Line-MMV" src={Line} alt="" />
          <p className="MMV-content">
            To deliver exceptional IT solutions that empower businesses, drive
            digital transformation, and foster sustainable growth in an
            ever-evolving technological landscape.
          </p>
        </div>
        <div className="MVV-Conteainer">
          <img className="MMV-GIF" src={Vision} alt="" />
          <p className="Big-text-MMV">
            Our <span style={{ color: "#EB6F20" }}>Vision</span>
          </p>
          <img className="Line-MMV" src={Line} alt="" />
          <p className="MMV-content">
            To be at the forefront of technological advancements, empowering
            businesses to leverage cutting-edge solutions that drive success and
            create a positive impact in the digital world.
          </p>
        </div>
        <div className="MVV-Conteainer">
          <img className="MMV-GIF" src={Values} alt="" />
          <p className="Big-text-MMV">
            Our <span style={{ color: "#EB6F20" }}>Values</span>
          </p>
          <img className="Line-MMV" src={Line} alt="" />
          <p className="MMV-content">
            We uphold integrity, excellence, collaboration, and
            client-centricity as our core values. By prioritizing these
            principles, we strive to exceed expectations and build long-term
            partnerships with our clients.
          </p>
        </div>
      </div>
      <WorkingStep />
      <Directors />
      <Counter />
      <Testimonials />
      <Quote />
      <Footer />
    </>
  );
}

export default AboutUs;
