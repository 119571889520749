import "./App.css";
import CustomCursor from "./Components/CustomCursor/CustomCursor";
import NavBar from "./Components/Navbar/NavBar";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./Components/Home/Home";
import AboutUs from "./Components/AboutUs/AboutUs";
import Portfolio from "./Components/Main-Portfolio/Portfolio";
import Blog from "./Components/Blog/Blog";
import ContactUs from "./Components/ContactUs-Main/Contact";
// Services
import UIUX from "./Components/Services/UiUX";
import Graphic from "./Components/Services/Graphic";
import Marketing from "./Components/Services/Marketing";
import Devlopment from "./Components/Services/WebApp";
import Hardware from "./Components/Services/Hardware";
import WordPress from "./Components/Services/WordPress";
// Full Blogs
import FullBlog1 from "./Components/Blog/FullBlog/Blog1";
// Portfolio Pages
import GVProject from "./Components/Portfolio/Pages/gujaratvandan";
import Secret17 from "./Components/Portfolio/Pages/Secret17";
import Qresources from "./Components/Portfolio/Pages/QuarecResources";
import MCXControl from "./Components/Portfolio/Pages/MCXControl";

function App() {
  return (
    <div className="App">
      {/* <CustomCursor /> */}
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Blog" element={<Blog />} />
        {/* Services */}
        <Route path="/Ui-Ux" element={<UIUX />} />
        <Route path="/Graphic" element={<Graphic />} />
        <Route path="/Marketing" element={<Marketing />} />
        <Route path="/Devlopment" element={<Devlopment />} />
        <Route path="/Hardware" element={<Hardware />} />
        <Route path="/WordPress" element={<WordPress />} />
        {/* Full Blogs */}
        <Route path="/Blog1" element={<FullBlog1 />} />
        {/* Portfolio */}
        <Route path="/GujaratVandan" element={<GVProject />} />
        <Route path="/Secret17" element={<Secret17 />} />
        <Route path="/QuarecResources" element={<Qresources />} />
        <Route path="/MCXControl" element={<MCXControl />} />
      </Routes>
    </div>
  );
}

export default App;
