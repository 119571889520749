import React, { useEffect } from "react";
import "./FullBlog.css";
import Banner from "../../AboutUs/Banner/Banner";
import BImg from "./IMGS/Rectang.png";
import { Icon } from "@iconify/react";
import arrowBackFill from "@iconify/icons-eva/arrow-back-fill";
import B1img from "./IMGS/Blank1.png";
import B2img from "./IMGS/Blank2.png";
import Quote from "../../AboutUs/Quote/Quote";
import Footer from "../../Footer/Footer";
import { useNavigate } from "react-router-dom";

function Blog1() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const Blog = () => {
    navigate("/Blog");
  };
  return (
    <>
      <Banner BannerName="Blogs" BannerImg={BImg} />
      <div className="Blog-Conteat">
        <p className="b-head" onClick={Blog}>
          <Icon className="Icon-arrow" icon={arrowBackFill} />
          &nbsp; IoT value set to accelerate through it
          <span style={{ color: "#EB6F20" }}> 2030:</span> Where and how to
          capture
        </p>
        <p className="Dateset">september 15, 2023</p>
        <div className="St-fblog">
          <img className="st-img" src={B2img} alt="" />
        </div>
        <p className="St-common">
          New research shows that the Internet of Things offers significant
          economic value potential, particularly in standardized production
          settings, but companies must achieve scale to capture it.
        </p>
        <h2 className="st-subhead">
          The Internet of Things: Catching up to an accelerating opportunity
        </h2>
        <p className="St-common">
          <b>The Internet of Things (IoT),</b> the convergence of the digital
          and physical worlds, has emerged as one of the fundamental trends
          underlying the digital transformation of business and the economy.
          From the fitness trackers we wear to the smart thermostats we use in
          our homes to the fleet-management solutions that tell us when our
          packages will arrive to the sensors that promote increased energy
          efficiency or monitor natural disasters resulting from climate change,
          the IoT is now embedded in the lives of consumers and the operations
          of enterprises and governments.
        </p>
        <h2 className="st-subhead">Defining the Internet of Things</h2>
        <p className="St-common">
          In 2015, the McKinsey Global Institute published a research report
          entitled The Internet of Things: Mapping the value beyond the hype.
          The report analyzed the economic potential that the IoT could unleash
          through consideration of hundreds of use cases in the physical
          settings in which they could be deployed.
        </p>
        <p className="St-common">
          Six years later, in a new report, The Internet of Things: Catching up
          to an accelerating opportunity, they updated the analysis to estimate
          how much of that value has been captured, how the potential value of
          the IoT could evolve in the coming decade, and the factors that
          explain both . The market has grown considerably in the intervening
          years but not as fast as expected in 2015. The IoT has faced headwinds
          related to change management, cost, talent, and cybersecurity,
          particularly in enterprises.
        </p>

        {/* <div className="Teg-line">
          <p className="line-style">
            “ Traveling can expose you to new environments and potential health
            risks, so it's crucial to take precautions to stay safe and healthy.
            ”
          </p>
        </div> */}
        <div className="St-fblog">
          <img className="st-img" src={B1img} alt="" />
        </div>
        <h2 className="st-subhead">Here’s what latest research found:</h2>
        <ul>
          <li className="St-common">
            The potential economic value that the IoT could unlock is large and
            growing. By 2030, we estimate that it could enable $5.5 trillion to
            $12.6 trillion in value globally, including the value captured by
            consumers and customers of IoT products and services.
          </li>
          <li className="St-common">
            The IoT’s economic-value potential is concentrated in certain
            settings (types of physical environments where IoT is deployed). It
            was found that the factory setting (which includes standardized
            production environments in manufacturing, hospitals, and other
            areas) will account for the largest amount of potential economic
            value from the IoT, around 26 percent, in 2030. The human-health
            setting is second, representing around 10 to 14 percent of estimated
            IoT economic value in 2030.
          </li>
          <li className="St-common">
            B2B applications are where the majority of IoT value can be created,
            with around 65 percent of the estimated IoT value potential by 2030.
            But the value of B2C applications is growing quickly, spurred by
            faster-than-expected adoption of IoT solutions within the home.
          </li>
          <li className="St-common">
            The 2030 IoT economic-value potential of the developed world will
            account for 55 percent of the global total, decreasing from 61
            percent in 2020. China is becoming a global IoT force as not only a
            manufacturing hub and technology supplier but also an end market for
            value creation.
          </li>
          <li className="St-common">
            While the potential economic value of IoT is considerable, capturing
            it has proved challenging, particularly in B2B settings. Many
            enterprises have struggled to transition from pilots to capture
            value at scale successfully. It is estimated that the total value
            captured by 2020 ($1.6 trillion), while considerable, to be in the
            lower end of the range of the scenarios mapped out in 2015.
            <br />
            The research of Mckinsey began in 2020 before the spread of
            SARS-CoV-2 virus, which causes COVID-19, led to a global pandemic in
            the first quarter of that year. The COVID-19 crisis represents a
            threat to both lives and livelihoods, but it also serves as a
            market-shaping force. While the report on which this article is
            based does not focus solely on the impact of the pandemic, that
            impact has been acting as a catalyst for the deployment of IoT
            solutions in specific areas as the world grapples with managing the
            pandemic and enabling a faster and safer return.
          </li>
        </ul>
      </div>
      <Quote />
      <Footer />
    </>
  );
}

export default Blog1;
