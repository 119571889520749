import React from "react";
import "./Banner.css";

function Banner(props) {
  const { BannerName, BannerImg } = props;
  return (
    <>
      <div className="About-main">
        <img className="B-img" src={BannerImg} alt="" />
        <h2 className="Banner-Title">{BannerName}</h2>
      </div>
    </>
  );
}

export default Banner;
