import React, { useEffect } from "react";
import Banner from "../../AboutUs/Banner/Banner";
import AboutImg from "../IMG/AboutUs.png";
import MainBa from "../IMG/theme4.jpg";
import "./Pages.css";
import Bootsrep from "../IMG/ICONS/Bootsrep.svg";
import MySql from "../IMG/ICONS/mysql.svg";
import Next from "../IMG/ICONS/React.svg";
import Exprees from "../IMG/ICONS/Express Js.svg";
import Nodejs from "../IMG/ICONS/nodejs.svg";
import ANTd from "../IMG/ICONS/Laravel.svg";
import Quate from "../../AboutUs/Quote/Quote";
import Footer from "../../Footer/Footer";

function QuarecResources() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Banner BannerName="Portfolio" BannerImg={AboutImg} />
      <h2 className="Web-app">Web Development</h2>
      <div className="Port-Mian">
        <h1 className="Pro-titek">Quarec Resources</h1>
        <p className="Pro-description">
          <b> Quarec Resources' </b>Human Resources website is a powerful tool
          for both job seekers and employers. Job seekers can easily apply for
          positions, upload their resumes, and track their applications through
          our user-friendly interface. For businesses, our 'Contact Us' form
          provides a straightforward way to connect with potential candidates.
          Additionally, our newsletter subscription feature ensures users stay
          updated on the latest job offers and industry news. Our website
          streamlines the recruitment process, fosters efficient communication,
          and keeps all parties informed, making it a valuable resource in the
          world of human resources. Explore our portfolio to see how we're
          shaping the future of HR solutions.
        </p>
        <img className="ImgLayout" src={MainBa} alt="" />
        <h2 className="Title-Fetures">Key Features</h2>
        <div className="Fetures-Maint">
          <div className="Tab-feture">
            <h3 className="Set-ups">Simplified Job Applications</h3>
            <p className="Nesda">
              Streamline the hiring process with our easy-to-use job application
              feature, empowering candidates to apply effortlessly.
            </p>
          </div>
          <div className="Tab-feture">
            <h3 className="Set-ups">Effortless Employee Search</h3>
            <p className="Nesda">
              Find the perfect talent for your company hassle-free, thanks to
              our efficient 'Find Employee' function.
            </p>
          </div>
          <div className="Tab-feture">
            <h3 className="Set-ups">Stay Updated with Job Alerts</h3>
            <p className="Nesda">
              Stay in the loop about new job opportunities through our
              newsletter subscription, ensuring you never miss an update.
            </p>
          </div>
        </div>
        {/* <h2 className="Title-Fetures">Technology</h2>
        <p className="Tehnopp">PHP</p> */}

        <h2 className="Title-Fetures">Framework & Librarys</h2>
        <div className="Tech-icon">
          <div className="Maintent-class">
            <img className="Icoosnic" src={Next} alt="" />
            <p className="Name-tecno">React Js</p>
          </div>
          <div className="Maintent-class">
            <img className="Icoosnic" src={Nodejs} alt="" />
            <p className="Name-tecno">Node Js</p>
          </div>

          <div className="Maintent-class">
            <img className="Icoosnic" src={Exprees} alt="" />
            <p className="Name-tecno">Express js</p>
          </div>
          <div className="Maintent-class">
            <img className="Icoosnic" src={Bootsrep} alt="" />
            <p className="Name-tecno">Bootstrap</p>
          </div>
          <div className="Maintent-class">
            <img className="Icoosnic" src={MySql} alt="" />
            <p className="Name-tecno">MySql</p>
          </div>
          <div className="Maintent-class">
            <img className="Icoosnic" src={ANTd} alt="" />
            <p className="Name-tecno">Laravel</p>
          </div>
          {/* <div className="Maintent-class">
            <img className="Icoosnic" src={PhotoShop} alt="" />
            <p className="Name-tecno">PhotoShop</p>
          </div> */}
        </div>
      </div>
      <Quate />
      <Footer />
    </>
  );
}

export default QuarecResources;
