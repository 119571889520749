import React, { useEffect } from "react";
import Banner from "../../AboutUs/Banner/Banner";
import AboutImg from "../IMG/AboutUs.png";
import MainBa from "../IMG/theme4.jpg";
import "./Pages.css";
import Bootsrep from "../IMG/ICONS/Bootsrep.svg";
import Mongodb from "../IMG/ICONS/mongodb.svg";
import Html from "../IMG/ICONS/Html.svg";
import MySql from "../IMG/ICONS/mysql.svg";
import Next from "../IMG/ICONS/React.svg";
import Exprees from "../IMG/ICONS/Express Js.svg";
import Nodejs from "../IMG/ICONS/nodejs.svg";
import ANTd from "../IMG/ICONS/Laravel.svg";
import MetrialUI from "../IMG/ICONS/Materialui.svg";
import Quate from "../../AboutUs/Quote/Quote";
import Footer from "../../Footer/Footer";

function Secret17() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Banner BannerName="Portfolio" BannerImg={AboutImg} />
      <h2 className="Web-app">Web Development</h2>
      <div className="Port-Mian">
        <h1 className="Pro-titek">Secret 17</h1>
        <p className="Pro-description">
          Explore this dynamic website showcasing
          <b>Secret17: Best Hair & Nail's salon in Ahmedabad's</b> premier hair
          and nail salon. Offering seamless appointment booking and free hair
          consultations, this user-friendly platform enhances the salon
          experience. With a modern design reflecting Secret17's trendy and
          professional ambiance, clients can effortlessly browse services and
          schedule appointments. As an integral part of your company's
          portfolio, this project exemplifies the fusion of technology and
          style, underscoring your commitment to versatile web solutions. For
          more details, visit our portfolio and witness the epitome of salon
          convenience and sophistication.
        </p>
        <img className="ImgLayout" src={MainBa} alt="" />
        <h2 className="Title-Fetures">Key Features</h2>
        <div className="Fetures-Maint">
          <div className="Tab-feture">
            <h3 className="Set-ups">Online Appointment Booking</h3>
            <p className="Nesda">
              Conveniently schedule your salon appointments online for a
              hassle-free experience.
            </p>
          </div>
          <div className="Tab-feture">
            <h3 className="Set-ups">Free Online Consultation</h3>
            <p className="Nesda">
              Receive personalized hair consultations online, free of charge,
              for expert guidance.
            </p>
          </div>
          <div className="Tab-feture">
            <h3 className="Set-ups">Service Showcase</h3>
            <p className="Nesda">
              Discover our extensive range of beauty services, tailored to your
              every need.
            </p>
          </div>
          <div className="Tab-feture">
            <h3 className="Set-ups">Client Reviews</h3>
            <p className="Nesda">
              Explore glowing client testimonials and find out why we're
              Ahmedabad's preferred choice for hair and nails.
            </p>
          </div>
        </div>
        {/* <h2 className="Title-Fetures">Technology</h2>
        <p className="Tehnopp">PHP</p> */}

        <h2 className="Title-Fetures">Framework & Librarys</h2>
        <div className="Tech-icon">
          <div className="Maintent-class">
            <img className="Icoosnic" src={Next} alt="" />
            <p className="Name-tecno">React Js</p>
          </div>
          <div className="Maintent-class">
            <img className="Icoosnic" src={Nodejs} alt="" />
            <p className="Name-tecno">Node Js</p>
          </div>

          <div className="Maintent-class">
            <img className="Icoosnic" src={Exprees} alt="" />
            <p className="Name-tecno">Express js</p>
          </div>
          <div className="Maintent-class">
            <img className="Icoosnic" src={Bootsrep} alt="" />
            <p className="Name-tecno">Bootstrap</p>
          </div>
          <div className="Maintent-class">
            <img className="Icoosnic" src={MySql} alt="" />
            <p className="Name-tecno">MySql</p>
          </div>
          <div className="Maintent-class">
            <img className="Icoosnic" src={ANTd} alt="" />
            <p className="Name-tecno">Laravel</p>
          </div>
          {/* <div className="Maintent-class">
            <img className="Icoosnic" src={PhotoShop} alt="" />
            <p className="Name-tecno">PhotoShop</p>
          </div> */}
        </div>
      </div>
      <Quate />
      <Footer />
    </>
  );
}

export default Secret17;
