import React, { useEffect } from "react";
import "./MPortfolio.css";
import Banner from "../AboutUs/Banner/Banner";
import PortfolioIMG from "./IMG/Rectangle.png";
import Img1 from "./IMG/Portfolio1.svg";
import Img2 from "./IMG/Portfolio2.svg";
import Img3 from "./IMG/Portfolio3.svg";
import Img4 from "./IMG/Portfolio4.svg";
import Footer from "../Footer/Footer";
import Quote from "../AboutUs/Quote/Quote";
import { useNavigate } from "react-router-dom";

function Portfolio() {
  const navigate = useNavigate();
  const GV = () => {
    navigate("/GujaratVandan");
  };
  const Secret17 = () => {
    navigate("/Secret17");
  };
  const Resources = () => {
    navigate("/QuarecResources");
  };
  const MCXControl = () => {
    navigate("/MCXControl");
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Banner BannerName="Portfolio" BannerImg={PortfolioIMG} />
      <h2 className="Main-Text-Portfolio">
        Explore Our <span style={{ color: "#EB6F20" }}>Creative</span> Journey
      </h2>
      <div className="Portfolio-block">
        <div className="Port-Card" onClick={GV}>
          <div className="Port-Img">
            <img className="Port-Bshow" src={Img1} alt="" />
            <p className="P-Name">Web Development</p>
            <p className="P-Work">Gujarat Vandan</p>
          </div>
        </div>
        <div className="Port-Card" onClick={Secret17}>
          <div className="Port-Img">
            <img className="Port-Bshow" src={Img2} alt="" />
            <p className="P-Name">Web Development</p>
            <p className="P-Work">Secret17</p>
          </div>
        </div>
        <div className="Port-Card" onClick={Resources}>
          <div className="Port-Img">
            <img className="Port-Bshow" src={Img3} alt="" />
            <p className="P-Name">Web Development</p>
            <p className="P-Work">Quarec Resources</p>
          </div>
        </div>
        <div className="Port-Card" onClick={MCXControl}>
          <div className="Port-Img">
            <img className="Port-Bshow" src={Img4} alt="" />
            <p className="P-Name">WordPress Development </p>
            <p className="P-Work">MCX Control</p>
          </div>
        </div>
      </div>
      <Quote />
      <Footer />
    </>
  );
}

export default Portfolio;
