import React from "react";
import "./Directors.css";
import { Icon } from "@iconify/react";
import linkedinFill from "@iconify/icons-ri/linkedin-fill";
import twitterIcon from "@iconify/icons-mdi/twitter";
import facebookFill from "@iconify/icons-ri/facebook-fill";

function Directors() {
  // Nikunj Patel Social Links
  const LinkedInNik = () => {
    window.open(
      "https://www.linkedin.com/in/nikunj-patel-52770416a/",
      "_blank"
    );
  };
  const TwitterNik = () => {
    window.open(
      "https://www.linkedin.com/in/nikunj-patel-52770416a/",
      "_blank"
    );
  };
  const FacebookNik = () => {
    window.open("https://www.facebook.com/nikunjpatel2892", "_blank");
  };
  // Khushbu Patel Social Links
  const LinkedInKP = () => {
    window.open(
      "https://www.linkedin.com/in/khushbu-patel-395116120/",
      "_blank"
    );
  };
  const TwitterKP = () => {
    window.open("https://www.facebook.com/nikunjpatel28", "_blank");
  };
  const FacebookKP = () => {
    window.open("https://www.facebook.com/khushbu.solanki.359", "_blank");
  };
  // Dinesh Rajput Social Links
  const LinkedInDR = () => {
    window.open(
      "https://www.linkedin.com/in/dineshsinh-rajput-475383142/?originalSubdomain=in",
      "_blank"
    );
  };
  const TwitterDR = () => {
    window.open(
      "https://twitter.com/i/flow/login?redirect_after_login=%2Frd7600454604",
      "_blank"
    );
  };
  const FacebookDR = () => {
    window.open("https://www.facebook.com/dineshsinh.rajput.585", "_blank");
  };
  return (
    <>
      <h1 className="D-header">
        Meet Our <span style={{ color: "#EB6F20" }}>Directors</span>
      </h1>
      <div className="D-card">
        <div className="Card-Director1">
          <div className="Card-decorec"></div>
          <div className="sub-oreanted">
            <p className="Text-Conpo">Nikunj Patel</p>
            <p className="Text-Conpo1">CEO & Founder</p>
          </div>
          <div className="Icosns">
            <Icon
              className="Icon-chges"
              onClick={LinkedInNik}
              icon={linkedinFill}
            />
            <Icon
              className="Icon-chges"
              onClick={TwitterNik}
              icon={twitterIcon}
            />
            <Icon
              className="Icon-chges"
              onClick={FacebookNik}
              icon={facebookFill}
            />
          </div>
        </div>
        {/* <div className="Card-Director2">
          <div className="Card-decorec"></div>
          <div className="sub-oreanted">
            <p className="Text-Conpo">Khushbu Patel</p>
            <p className="Text-Conpo1">CEO & Founder</p>
          </div>
          <div className="Icosns">
            <Icon
              className="Icon-chges"
              onClick={LinkedInKP}
              icon={linkedinFill}
            />
            <Icon
              className="Icon-chges"
              onClick={TwitterKP}
              icon={twitterIcon}
            />
            <Icon
              className="Icon-chges"
              onClick={FacebookKP}
              icon={facebookFill}
            />
          </div>
        </div> */}
        <div className="Card-Director3">
          <div className="Card-decorec"></div>
          <div className="sub-oreanted">
            <p className="Text-Conpo">Dinesh Rajput</p>
            <p className="Text-Conpo1">CEO & Founder</p>
          </div>
          <div className="Icosns">
            <Icon
              className="Icon-chges"
              onClick={LinkedInDR}
              icon={linkedinFill}
            />
            <Icon
              className="Icon-chges"
              onClick={TwitterDR}
              icon={twitterIcon}
            />
            <Icon
              className="Icon-chges"
              onClick={FacebookDR}
              icon={facebookFill}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Directors;
