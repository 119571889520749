import React, { useEffect } from "react";
import Banner from "../AboutUs/Banner/Banner";
import BImg from "./IMG/Banner.png";
import Main from "./IMG/Normal Banner/Graphic Design.jpg";
import "./Services.css";
// GIF
import SGIF1 from "./IMG/Animated GIF/Graphic1.gif";
import SGIF2 from "./IMG/Animated GIF/Graphic2.gif";
import SGIF3 from "./IMG/Animated GIF/Graphic3.gif";
import Line from "./IMG/UIUX/Grupline.svg";
import UIUX from "./IMG/Animated SVG/svg animation graphic Design.svg";
//Components
import Portfolio from "../Portfolio/Portfolio";
import Testimonials from "../Home/Testimonials/Testimonials";
import Quate from "../AboutUs/Quote/Quote";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

function Graphic() {
  const navigate = useNavigate();
  const GetInTouch = () => {
    navigate("/ContactUs");
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Banner BannerName="Graphic Design" BannerImg={BImg} />
      <div className="Ser-Con">
        <h1 className="Main-write">
          Elevating Ideas Through
          <span style={{ color: "#EB6F20" }}> Creative Graphic Design </span>
          for Impactful Visual Communication.
        </h1>
        <div className="Stage-Compo">
          <div className="Satge-content">
            <p className="line-stages">
              Enhancing Visual Communication Through Graphic Design Concepts
            </p>
            <p className="lining-stageing">
              Graphic design plays a pivotal role in delivering impactful visual
              communication. It encompasses the art of crafting compelling
              visuals and layouts. At our graphic design studio, we specialize
              in creating captivating designs that resonate with your audience
              and leave a lasting impression.
            </p>
          </div>
          <div className="Stage-Img">
            <img className="Main-Uiux" src={Main} alt="" />
          </div>
        </div>
        <div className="Cards-main">
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF1} alt="" />
            <p className="Text-vuy">Customized Logo Design</p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              We create an alluring logo designs, because we know logo is prime
              identity of every business.
            </p>
          </div>
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF2} alt="" />
            <p className="Text-vuy">Broacher, Banner and Hoarding creatives</p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              we put some diligence in every piece of design which gives you a
              wow factor.
            </p>
          </div>
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF3} alt="" />
            <p className="Text-vuy">Social Media Creative</p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              We don't just make a post, we portrait the feelings of our client.
            </p>
          </div>
        </div>
        <h1 className="Acomplesh">
          Experience&nbsp;
          <span style={{ color: "#EB6F20" }}>
             the design excellence our proficient graphic designers bring UX/UI
            design 
          </span>
          .
        </h1>
        <div className="proficient">
          <div className="profic">
            <img className="Uxdjw" src={UIUX} alt="" />
          </div>
          <div className="profic">
            <h2 className="Texticjh">
              Skilled designers achieve what in
              <span style={{ color: "#EB6F20" }}> UX/UI design</span>?
            </h2>
            <p className="Textarea918">
              Enhance user satisfaction and engagement with our expert UI/UX
              design services. Craft intuitive interfaces and seamless
              interactions that captivate your audience and elevate your digital
              experiences
            </p>
            <p className="Textarea918">
              Our UI/UX design services focus on creating visually appealing and
              user-friendly interfaces, ensuring an exceptional user experience
              that delights and engages your target audience.
            </p>
            <button className="Ubtn" onClick={GetInTouch}>
              Get In Touch
            </button>
          </div>
        </div>
      </div>
      <Portfolio />
      <Testimonials />
      <Quate />
      <Footer />
    </>
  );
}

export default Graphic;
