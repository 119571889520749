import React from "react";
import "./Footer.css";
import Logo from "./IMG/Logo.svg";
import { Icon } from "@iconify/react";
import sendIcon from "@iconify/icons-fa/send";
import linkedinLine from "@iconify/icons-ri/linkedin-line";
import instagramIcon from "@iconify/icons-mdi/instagram";
import behanceFill from "@iconify/icons-ri/behance-fill";
import dribbleIcon from "@iconify/icons-icon-park-outline/dribble";
import twitterIcon from "@iconify/icons-mdi/twitter";
import facebookFill from "@iconify/icons-ri/facebook-fill";
import locationFilled from "@iconify/icons-carbon/location-filled";
import phoneIcon from "@iconify/icons-mdi/phone";
// Gallery imgs
import Gallery1 from "./IMG/Gallery1.png";
import Gallery2 from "./IMG/Gallery2.png";
import Gallery3 from "./IMG/Gallery3.png";
import NewsLatter from "./NewsLatter";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  const Facebook = () => {
    window.open("https://www.facebook.com/quarecitsolutions", "_blank");
  };
  const Twitter = () => {
    window.open("https://twitter.com/QuarecIT", "_blank");
  };
  const Dribbble = () => {
    window.open("https://dribbble.com/Quarecitsolutions/about", "_blank");
  };
  const Behance = () => {
    window.open("https://www.behance.net/quarecitsolutions", "_blank");
  };
  const Instagram = () => {
    window.open("https://www.instagram.com/quarecitsolution/", "_blank");
  };
  const Linkedin = () => {
    window.open(
      "https://www.linkedin.com/company/quarec-it-solutions/",
      "_blank"
    );
  };
  return (
    <>
      <div className="Footer-Main">
        <div className="Footer-boundry">
          <div className="F-Sections">
            <img className="Footer-logo" src={Logo} alt="" />
            <p className="Footer-text">
              We work with a passion of taking challenges and creating new ones
              in IT sector.
            </p>
          </div>
          <div className="F-Sections">
            <h1 className="Heading-footer">Newsletter</h1>
            <p className="Fotter-contant">
              Subscribe our newsletter to get our latest update & news.
            </p>
            <NewsLatter />
            <div className="Flexi-icons">
              <Icon
                className="Social-Icons"
                onClick={Facebook}
                icon={facebookFill}
              />
              <Icon
                className="Social-Icons"
                onClick={Twitter}
                icon={twitterIcon}
              />
              <Icon
                className="Social-Icons"
                onClick={Dribbble}
                icon={dribbleIcon}
              />
              <Icon
                className="Social-Icons"
                onClick={Behance}
                icon={behanceFill}
              />
              <Icon
                className="Social-Icons"
                onClick={Instagram}
                icon={instagramIcon}
              />
              <Icon
                className="Social-Icons"
                onClick={Linkedin}
                icon={linkedinLine}
              />
            </div>
          </div>
          <div className="F-Sections">
            <h1 className="Heading-footer">Official info</h1>
            <div className="Footer-Contact">
              <Icon className="location-icon" icon={locationFilled} />
              <p className="Footer-textcontact">
                615, Sakar-9 ,Ashram road Ahmedabad, Gujarat, India
              </p>
            </div>
            <div className="Footer-Contact1">
              <Icon className="location-phone" icon={phoneIcon} />
              <a className="Footer-textcontact">+91-9699656667</a>
            </div>
            <h2 className="Sub-head">Open Hours</h2>
            <p className="Footer-timeing">
              Mon – Sat: 10 am – 7 pm, Sunday: Closed
            </p>
          </div>
          <div className="F-Sections">
            <h1 className="Heading-footer">Gallery</h1>
            <div className="Gallery-Main">
              <div className="Gallery-Imgs">
                <img className="G-Img" src={Gallery1} alt="" />
                <img className="G-Img" src={Gallery2} alt="" />
                <img className="G-Img" src={Gallery3} alt="" />
              </div>
              <div className="Gallery-Imgs">
                <img className="G-Img" src={Gallery3} alt="" />
                <img className="G-Img" src={Gallery2} alt="" />
                <img className="G-Img" src={Gallery1} alt="" />
              </div>
            </div>
          </div>
        </div>
        <p className="Copyright">
          Copyright &copy; 2023 QuarecITSolutions. All Rights Reserved.
        </p>
      </div>
    </>
  );
}

export default Footer;
