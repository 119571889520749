import React, { useEffect } from "react";
import Banner from "../AboutUs/Banner/Banner";
import BImg from "./IMG/Banner.png";
import Main from "./IMG/Normal Banner/Marketing.png";
import "./Services.css";
// GIF
import SGIF1 from "./IMG/Animated GIF/Marketing1.gif";
import SGIF2 from "./IMG/Animated GIF/Marketing2.gif";
import SGIF3 from "./IMG/Animated GIF/Marketing3.gif";
import Line from "./IMG/UIUX/Grupline.svg";
import UIUX from "./IMG/Animated SVG/svg animation Digital Marketing.svg";
//Components
import Portfolio from "../Portfolio/Portfolio";
import Testimonials from "../Home/Testimonials/Testimonials";
import Quate from "../AboutUs/Quote/Quote";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

function Graphic() {
  const navigate = useNavigate();
  const GetInTouch = () => {
    navigate("/ContactUs");
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Banner BannerName="SEO & Marketing" BannerImg={BImg} />
      <div className="Ser-Con">
        <h1 className="Main-write">
          Elevating Brands Through Strategic
          <span style={{ color: "#EB6F20" }}> SEO & Marketing </span>
          for Effective Online Presence
        </h1>
        <div className="Stage-Compo">
          <div className="Satge-content">
            <p className="line-stages">
              Elevating Digital Outreach Through Strategic SEO & Marketing
              Solutions
            </p>
            <p className="lining-stageing">
              SEO and marketing are the driving forces behind impactful digital
              outreach. They involve the science of optimizing strategies and
              content for maximum visibility and audience engagement. At our SEO
              and marketing agency, we specialize in crafting effective
              campaigns that resonate with your target audience and leave a
              lasting online footprint.
            </p>
          </div>
          <div className="Stage-Img">
            <img className="Main-Uiux" src={Main} alt="" />
          </div>
        </div>
        <div className="Cards-main">
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF1} alt="" />
            <p className="Text-vuy">Search Engine optimization </p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              Our strategy is focused on building engaging and consistent
              360-degree brand experiences across all customer touchpoints,
              ensuring that your company stands out in a cluttered marketplace
            </p>
          </div>
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF2} alt="" />
            <p className="Text-vuy">Pay Per Click</p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              Our daily PPC campaign optimization aims to increase click-through
              rate (CTR) and maximize the return on your marketing investment
              (ROI).
            </p>
          </div>
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF3} alt="" />
            <p className="Text-vuy">E-mail Marketing</p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              We focus on identifying key communication points in the customer
              journey and incorporate a consistent calendar cadence and
              strategic initiative roadmap prioritizing program opportunities.
            </p>
          </div>
        </div>
        <h1 className="Acomplesh">
          Feel the power of our
          <span style={{ color: "#EB6F20" }}> SEO and marketing </span>
          strategies in action.
        </h1>
        <div className="proficient">
          <div className="profic">
            <img className="Uxdjw" src={UIUX} alt="" />
          </div>
          <div className="profic">
            <h2 className="Texticjh">
              Realize the potential of your brand with our
              <span style={{ color: "#EB6F20" }}> SEO and marketing </span>
            </h2>
            <p className="Textarea918">
              Boost audience engagement and satisfaction with our SEO and
              marketing mastery. We develop data-driven strategies and
              captivating content that elevate your online reach.
            </p>
            <p className="Textarea918">
              Our SEO and marketing services revolve around maximizing
              visibility and user engagement, ensuring a compelling online
              presence that resonates with and engages your target audience.
            </p>
            <button className="Ubtn" onClick={GetInTouch}>
              Get In Touch
            </button>
          </div>
        </div>
      </div>
      <Portfolio />
      <Testimonials />
      <Quate />
      <Footer />
    </>
  );
}

export default Graphic;
