import React, { useState, useEffect } from "react";
import "./ContactUs.css";
import Phonei from "./Icons/Phone.svg";
import Location from "./Icons/Location.svg";
import Mail from "./Icons/Mail.svg";
import Time from "./Icons/Time.svg";
import { event } from "jquery";
import Form from "./Form";

function ContactUs() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="Main-Contact">
        <p className="C-latter">Contact Us</p>
        <h1 className="Contact-text">
          It’s very easy to <span style={{ color: "#EB6F20" }}>Connect</span>{" "}
          with us
        </h1>
        <div className="Form-info">
          <div className="Contact-Form">
            <Form />
          </div>
          <div className="Contect-Info">
            <div className="Info-flex">
              <div className="box-info">
                <div className="Iconicals-width">
                  <img className="Info-Icon" src={Phonei} alt="" />
                </div>
                <div className="Contact-tab">
                  <p className="Contact-Dialer">Call Us for help!</p>
                  <a className="Dial" href="tel:+91 9699656667">
                    +91-9699656667
                  </a>
                </div>
              </div>
              <div className="box-info">
                <div className="Iconicals-width">
                  <img className="Info-Icon" src={Time} alt="" />
                </div>
                <div className="Contact-tab">
                  <p className="Contact-Dialer">Official Timing:</p>
                  <a className="Dial">Mon-Sat 10:00 - 7:00</a>
                </div>
              </div>
            </div>
            <div className="Info-flex">
              <div className="box-info">
                <div className="Iconicals-width">
                  <img className="Info-Icon" src={Mail} alt="" />
                </div>
                <div className="Contact-tab">
                  <p className="Contact-Dialer">Our Mail Address</p>
                  <a className="Dial" href="mailto:quarecitsolutions@gmail.com">
                    quarecitsolutions@gmail.com
                  </a>
                  <br />
                  <a className="Dial" href="mailto:it.quarec@gmail.com">
                    it.quarec@gmail.com
                  </a>
                </div>
              </div>
              <div className="box-info">
                <div className="Iconicals-width">
                  <img className="Info-Icon" src={Location} alt="" />
                </div>
                <div className="Contact-tab">
                  <p className="Contact-Dialer">Our Locations</p>
                  <a className="Dial">
                    615, Sakar-9 ,Ashram road Ahmedabad, Gujarat, India
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
