import React, { useEffect } from "react";

import Banner from "./Banner/Banner";
import Portfolio from "../Portfolio/Portfolio";
import Testimonials from "./Testimonials/Testimonials";
import ContactUs from "../ContactUs/ContactUs";
import Footer from "../Footer/Footer";

function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Banner />
      <Portfolio />
      <Testimonials />
      <ContactUs />
      <Footer />
    </>
  );
}

export default Home;
