import React, { useEffect } from "react";
import Banner from "../../AboutUs/Banner/Banner";
import AboutImg from "../IMG/AboutUs.png";
import MainBa from "../IMG/theme4.jpg";
import "./Pages.css";
import Bootsrep from "../IMG/ICONS/Bootsrep.svg";
import MySql from "../IMG/ICONS/mysql.svg";
import Next from "../IMG/ICONS/wordpress.svg";
import Exprees from "../IMG/ICONS/Express Js.svg";
import Nodejs from "../IMG/ICONS/nodejs.svg";
import ANTd from "../IMG/ICONS/Laravel.svg";
import Quate from "../../AboutUs/Quote/Quote";
import Footer from "../../Footer/Footer";

function MCXControl() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Banner BannerName="Portfolio" BannerImg={AboutImg} />
      <h2 className="Web-app">Web Development</h2>
      <div className="Port-Mian">
        <h1 className="Pro-titek">MCXControl</h1>
        <p className="Pro-description">
          <b> MCX Control</b> is a dynamic platform enabling users to register
          and log in, facilitating the sharing of valuable trading insights,
          especially for Gold, Silver, and various other commodities. Users can
          contribute their trading tips, creating a vibrant community of
          traders. Moreover, the platform displays real-time MCX commodity
          rates, keeping users informed and aiding in their trading decisions.
          MCX Control's user-friendly interface fosters a seamless experience,
          making it a valuable resource for traders and investors alike. Explore
          our portfolio to witness how we empower traders through technology and
          information-sharing on this cutting-edge platform.
        </p>
        <img className="ImgLayout" src={MainBa} alt="" />
        <h2 className="Title-Fetures">Key Features</h2>
        <div className="Fetures-Maint">
          <div className="Tab-feture">
            <h3 className="Set-ups">Real-Time MCX Price Display</h3>
            <p className="Nesda">
              Stay informed with live MCX commodity prices, ensuring you make
              well-informed trading decisions at a glance.
            </p>
          </div>
          <div className="Tab-feture">
            <h3 className="Set-ups">Trader Tip Sharing</h3>
            <p className="Nesda">
              Collaborate with fellow traders by sharing your trading insights
              and strategies on MCX Control.
            </p>
          </div>
          <div className="Tab-feture">
            <h3 className="Set-ups">Client Tip Access</h3>
            <p className="Nesda">
              Access expert trading tips and strategies from top traders to
              enhance your trading journey on MCX Control.
            </p>
          </div>
        </div>
        {/* <h2 className="Title-Fetures">Technology</h2>
        <p className="Tehnopp">PHP</p> */}

        <h2 className="Title-Fetures">Framework & Librarys</h2>
        <div className="Tech-icon">
          <div className="Maintent-class">
            <img className="Icoosnic" src={Next} alt="" />
            <p className="Name-tecno">wordpress</p>
          </div>

          <div className="Maintent-class">
            <img className="Icoosnic" src={MySql} alt="" />
            <p className="Name-tecno">MySql</p>
          </div>
          <div className="Maintent-class">
            <img className="Icoosnic" src={ANTd} alt="" />
            <p className="Name-tecno">Laravel</p>
          </div>
          {/* <div className="Maintent-class">
            <img className="Icoosnic" src={PhotoShop} alt="" />
            <p className="Name-tecno">PhotoShop</p>
          </div> */}
        </div>
      </div>
      <Quate />
      <Footer />
    </>
  );
}

export default MCXControl;
