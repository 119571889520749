import React, { useState } from "react";
import "./Navbar.css";
import Logo from "./Group.svg";
// import Logo from "./Logo.svg";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import worldwideLocation from "@iconify/icons-wpf/worldwide-location";
import facebookFill from "@iconify/icons-ri/facebook-fill";
import instagramIcon from "@iconify/icons-mdi/instagram";
import twitterIcon from "@iconify/icons-mdi/twitter";
import linkedinLine from "@iconify/icons-ri/linkedin-line";
import behanceFill from "@iconify/icons-ri/behance-fill";
import dribbleIcon from "@iconify/icons-icon-park-outline/dribble";
import hamburgerMenuBroken from "@iconify/icons-solar/hamburger-menu-broken";
import Btn from "../Home/Banner/Button/Button";

function NavBar() {
  const instagramProfileUrl = "https://www.instagram.com/quarecitsolution/";
  const LinkedinProfileUrl =
    "https://www.linkedin.com/company/quarec-it-solutions/";
  const facebookProfileUrl = "https://www.facebook.com/quarecitsolutions";
  const DribbbleProfileUrl = "https://dribbble.com/Quarecitsolutions/about";
  const BehanceProfileUrl = "https://www.behance.net/quarecitsolutions";
  const twitterProfileUrl = "https://twitter.com/QuarecIT";
  const [isMobileNavbarOpen, setMobileNavbarOpen] = useState(false);

  const toggleMobileNavbar = () => {
    setMobileNavbarOpen(!isMobileNavbarOpen);
  };

  const closeMobileNavbar = () => {
    setMobileNavbarOpen(false);
  };
  return (
    <>
      <div className="wrap">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-md d-flex align-items-center">
              <p className="mb-0 phone">
                <Icon className="icons" icon={worldwideLocation} /> 615,
                Sakar-9, Ashram road , Ahmedabad.
              </p>
            </div>
            <div className="col-12 col-md d-flex justify-content-md-end">
              <div className="social-media">
                <p className="mb-0 d-flex">
                  <a
                    href={facebookProfileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Icon className="Iconsetup" icon={facebookFill} />
                  </a>
                  <a
                    href={instagramProfileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Icon className="Iconsetup" icon={instagramIcon} />
                  </a>
                  <a
                    href={twitterProfileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Icon className="Iconsetup" icon={twitterIcon} />
                  </a>
                  <a
                    href={LinkedinProfileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Icon className="Iconsetup" icon={linkedinLine} />
                  </a>
                  <a
                    href={BehanceProfileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Icon className="Iconsetup" icon={behanceFill} />
                  </a>
                  <a
                    href={DribbbleProfileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Icon className="Iconsetup" icon={dribbleIcon} />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav
        className={`navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light ${
          isMobileNavbarOpen ? "mobile-open" : ""
        }`}
        id="ftco-navbar"
      >
        <div className="container">
          <a className="navbar-brand" to="/">
            <img src={Logo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMobileNavbar}
          >
            <span className="oi oi-menu"></span>
            <Icon icon={hamburgerMenuBroken} color="#055392" />
          </button>

          <div
            className={`collapse navbar-collapse ${
              isMobileNavbarOpen ? "show" : ""
            }`}
            id="ftco-nav"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink
                  exact
                  to="/"
                  className="nav-link"
                  activeClassName="active"
                  onClick={closeMobileNavbar}
                >
                  HOME
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/AboutUs"
                  className="nav-link"
                  activeClassName="active"
                  onClick={closeMobileNavbar}
                >
                  ABOUT US
                </NavLink>
              </li>
              <li className="nav-item services-dropdown">
                <div className="nav-link">
                  SERVICES
                  <div className="dropdown-content">
                    <NavLink
                      to="/Ui-Ux"
                      className="dropdown-link"
                      activeClassName="active"
                      onClick={closeMobileNavbar}
                    >
                      UI-UX Design
                    </NavLink>
                    <NavLink
                      to="/Graphic"
                      className="dropdown-link"
                      activeClassName="active"
                      onClick={closeMobileNavbar}
                    >
                      Graphic Design
                    </NavLink>
                    <NavLink
                      to="/Marketing"
                      className="dropdown-link"
                      activeClassName="active"
                      onClick={closeMobileNavbar}
                    >
                      SEO & Marketing
                    </NavLink>
                    <NavLink
                      to="/Devlopment"
                      className="dropdown-link"
                      activeClassName="active"
                      onClick={closeMobileNavbar}
                    >
                      Web & App Development
                    </NavLink>
                    <NavLink
                      to="/Hardware"
                      className="dropdown-link"
                      activeClassName="active"
                      onClick={closeMobileNavbar}
                    >
                      Hardware Solutions
                    </NavLink>
                    <NavLink
                      to="/WordPress"
                      className="dropdown-link"
                      activeClassName="active"
                      onClick={closeMobileNavbar}
                    >
                      WordPress Development
                    </NavLink>
                  </div>
                </div>
              </li>

              <li className="nav-item">
                <NavLink
                  to="/Portfolio"
                  className="nav-link"
                  activeClassName="active"
                  onClick={closeMobileNavbar}
                >
                  PORTFOLIO
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Blog"
                  className="nav-link"
                  activeClassName="active"
                  onClick={closeMobileNavbar}
                >
                  BLOGS
                </NavLink>
              </li>
              <li
                className="nav-item cta"
                style={{ display: "flex", alignItems: "center" }}
              >
                {/* <NavLink
                  to="/ContactUs"
                  onClick={closeMobileNavbar}
                  className="nav-link"
                >
                  Get In Touch
                </NavLink> */}
                <Btn />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
