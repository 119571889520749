import React, { useEffect } from "react";
import Banner from "../../AboutUs/Banner/Banner";
import AboutImg from "../IMG/AboutUs.png";
import MainBa from "../IMG/theme4.jpg";
import "./Pages.css";
import Bootsrep from "../IMG/ICONS/Bootsrep.svg";
import Mongodb from "../IMG/ICONS/mongodb.svg";
import Html from "../IMG/ICONS/Html.svg";
import MySql from "../IMG/ICONS/mysql.svg";
import PhotoShop from "../IMG/ICONS/photoshop.svg";
import Wordpress from "../IMG/ICONS/wordpress.svg";
import Next from "../IMG/ICONS/Next.svg";
import Exprees from "../IMG/ICONS/Express Js.svg";
import Nodejs from "../IMG/ICONS/nodejs.svg";
import ANTd from "../IMG/ICONS/Antd.svg";
import MetrialUI from "../IMG/ICONS/Materialui.svg";
import Quate from "../../AboutUs/Quote/Quote";
import Footer from "../../Footer/Footer";

function Gujaratvandan() {
  // Start with an uppercase letter
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);

  // Rest of your component code
  // }
  return (
    <>
      <Banner BannerName="Portfolio" BannerImg={AboutImg} />
      <h2 className="Web-app">Web/App Development</h2>
      <div className="Port-Mian">
        <h1 className="Pro-titek">Gujarat Vandan</h1>
        <p className="Pro-description">
          <b>Gujarat Vandan</b> is your go-to online platform for everything
          related to Gujarat. We pride ourselves on being the source for the
          latest news, cultural insights, and community engagement in this
          vibrant state. Our website offers a seamless blend of traditional
          journalism and modern multimedia, with real-time news updates and
          in-depth video coverage. For those seeking a more immersive
          experience, we provide a weekly digital newspaper with comprehensive
          articles, analyses, and cultural features. Gujarat Vandan is more than
          just a news outlet; it's a community hub where readers can connect,
          share opinions, and stay informed about the rich heritage and dynamic
          developments of Gujarat. With responsive design, customizable alerts,
          and a powerful search function, we ensure that our readers can access
          the content they desire, anytime and anywhere. Join us in celebrating
          Gujarat's culture, staying up-to-date with breaking news, and being
          part of our growing online community.
        </p>
        <img className="ImgLayout" src={MainBa} alt="" />
        <h2 className="Title-Fetures">Key Features</h2>
        <div className="Fetures-Maint">
          <div className="Tab-feture">
            <h3 className="Set-ups">Video News Reels</h3>
            <p className="Nesda">
              Experience news like never before with our dynamic video news
              reels, delivering the latest updates in an engaging multimedia
              format.
            </p>
          </div>
          <div className="Tab-feture">
            <h3 className="Set-ups">Share Anywhere</h3>
            <p className="Nesda">
              Easily share news articles across your favorite applications,
              keeping friends and family informed effortlessly.
            </p>
          </div>
          <div className="Tab-feture">
            <h3 className="Set-ups">Download News Paper</h3>
            <p className="Nesda">
              Access our weekly newspaper with a simple click, ensuring you can
              read offline at your convenience.
            </p>
          </div>
          <div className="Tab-feture">
            <h3 className="Set-ups">Trending News Alerts</h3>
            <p className="Nesda">
              Stay in the loop with trending news notifications, delivering hot
              stories directly to your device, so you never miss a beat.
            </p>
          </div>
        </div>
        {/* <h2 className="Title-Fetures">Technology</h2>
        <p className="Tehnopp">PHP</p> */}

        <h2 className="Title-Fetures">Framework & Librarys</h2>
        <div className="Tech-icon">
          <div className="Maintent-class">
            <img className="Icoosnic" src={Next} alt="" />
            <p className="Name-tecno">Next Js</p>
          </div>
          <div className="Maintent-class">
            <img className="Icoosnic" src={Nodejs} alt="" />
            <p className="Name-tecno">Node Js</p>
          </div>
          <div className="Maintent-class">
            <img className="Icoosnic" src={Mongodb} alt="" />
            <p className="Name-tecno">Mongo DB</p>
          </div>
          <div className="Maintent-class">
            <img className="Icoosnic" src={Exprees} alt="" />
            <p className="Name-tecno">Express js</p>
          </div>
          <div className="Maintent-class">
            <img className="Icoosnic" src={Bootsrep} alt="" />
            <p className="Name-tecno">Bootstrap</p>
          </div>
          <div className="Maintent-class">
            <img className="Icoosnic" src={MetrialUI} alt="" />
            <p className="Name-tecno">Material UI</p>
          </div>
          <div className="Maintent-class">
            <img className="Icoosnic" src={ANTd} alt="" />
            <p className="Name-tecno">Ant Design</p>
          </div>
          {/* <div className="Maintent-class">
            <img className="Icoosnic" src={PhotoShop} alt="" />
            <p className="Name-tecno">PhotoShop</p>
          </div> */}
        </div>
      </div>
      <Quate />
      <Footer />
    </>
  );
}

export default Gujaratvandan;
