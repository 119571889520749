import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ContactUs.css";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      nameError: "",
      emailError: "",
      subjectError: "",
      messageError: "",
      formSubmitted: false,
      formError: false,
    };
  }
  validateForm = () => {
    let hasError = false;

    if (!this.state.name.trim()) {
      this.setState({ nameError: "Name is required" });
      hasError = true;
    } else if (!/^[a-zA-Z\s]+$/.test(this.state.name)) {
      this.setState({
        nameError: "Name should only contain alphabetic characters and spaces",
      });
      hasError = true;
    } else if (this.state.name.length < 3) {
      this.setState({ nameError: "Name should be at least 3 characters long" });
      hasError = true;
    } else {
      this.setState({ nameError: "" });
    }

    if (!this.state.email.trim()) {
      this.setState({ emailError: "Email is required" });
      hasError = true;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email)) {
      this.setState({ emailError: "Please enter a valid email address" });
      hasError = true;
    } else {
      this.setState({ emailError: "" });
    }

    if (!this.state.subject.trim()) {
      this.setState({ subjectError: "Subject is required" });
      hasError = true;
    } else {
      this.setState({ subjectError: "" });
    }

    if (!this.state.message.trim()) {
      this.setState({ messageError: "Message is required" });
      hasError = true;
    } else {
      this.setState({ messageError: "" });
    }

    return !hasError;
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      const formData = new FormData();
      formData.append("name", this.state.name);
      formData.append("email", this.state.email);
      formData.append("subject", this.state.subject);
      formData.append("message", this.state.message);

      fetch("https://quarecres.mcxcontrol.com/api/it_contact_api.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            this.setState({
              formSubmitted: true,
              formError: false,
              name: "",
              email: "",
              subject: "",
              message: "",
            });

            toast.success("Form submitted successfully!");
          } else {
            this.setState({ formSubmitted: false, formError: true });

            toast.error("Error submitting form.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          this.setState({ formSubmitted: false, formError: true });

          toast.error("Error submitting form.");
        });
    }
  };

  render() {
    return (
      <>
        <ToastContainer />
        <form onSubmit={this.handleSubmit}>
          <div className="subdivision">
            <div className="Inputs-item">
              <p className="List-Items">Your Name</p>
              <input
                name="name"
                className="input-item"
                type="text"
                value={this.state.name}
                onChange={this.handleInputChange}
              />
              {this.state.nameError && (
                <p className="Err">{this.state.nameError}</p>
              )}
            </div>
            <div className="Inputs-item">
              <p className="List-Items">Phone Number</p>
              <input
                name="subject"
                className="input-item"
                type="number"
                value={this.state.subject}
                onChange={this.handleInputChange}
              />
              {this.state.subjectError && (
                <p className="Err">{this.state.subjectError}</p>
              )}
            </div>
          </div>
          <div className="full-input">
            <p className="List-Items">Email I'd</p>
            <input
              className="input-item1"
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.handleInputChange}
            />
            {this.state.emailError && (
              <p className="Err">{this.state.emailError}</p>
            )}
          </div>
          <div className="full-input">
            <p className="List-Items">Type your message...</p>
            <input
              className="input-item1"
              type="text"
              name="message"
              value={this.state.message}
              onChange={this.handleInputChange}
            />
            {this.state.messageError && (
              <p className="Err">{this.state.messageError}</p>
            )}
          </div>

          <div style={{ float: "left" }}>
            <div className="centerer">
              <div className="new-class-name">
                <button type="submit" className="btn-6">
                  Send Message Now
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default Form;
