import React, { useEffect } from "react";
import Banner from "../AboutUs/Banner/Banner";
import BImg from "./IMG/Banner.png";
import Main from "./IMG/UIUX/main.svg";
import "./Services.css";
// GIF
import SGIF1 from "./IMG/UIUX/UIUX1.gif";
import SGIF2 from "./IMG/UIUX/UIUX2.gif";
import SGIF3 from "./IMG/UIUX/UIUX3.gif";
import Line from "./IMG/UIUX/Grupline.svg";
import UIUX from "./IMG/UIUX/UIUX.svg";
//Components
import Portfolio from "../Portfolio/Portfolio";
import Testimonials from "../Home/Testimonials/Testimonials";
import Quate from "../AboutUs/Quote/Quote";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

function UiUX() {
  const navigate = useNavigate();
  const GetInTouch = () => {
    navigate("/ContactUs");
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Banner BannerName="UI-UX Design" BannerImg={BImg} />
      <div className="Ser-Con">
        <h1 className="Main-write">
          Enhancing <span style={{ color: "#EB6F20" }}>User Experience</span>
          Through Wireframes and Prototypes
        </h1>
        <div className="Stage-Compo">
          <div className="Satge-content">
            <p className="line-stages">
              Improving UX with Wireframes and Prototypes
            </p>
            <p className="lining-stageing">
              UX/UI design is a crucial element in creating a successful website
              or mobile app. The user experience (UX) refers to how users
              interact with the website or app, while the user interface (UI) is
              the visual design that users see and interact with. At our UX/UI
              design company, we specialize in creating intuitive and engaging
              user experiences that drive conversions and customer satisfaction.
            </p>
          </div>
          <div className="Stage-Img">
            <img className="Main-Uiux" src={Main} alt="" />
          </div>
        </div>
        <div className="Cards-main">
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF1} alt="" />
            <p className="Text-vuy">If you have idea for MVP</p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              Come to us with your next big idea or MVP, and we will help you
              build it ground up.
            </p>
          </div>
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF2} alt="" />
            <p className="Text-vuy">If you have Wireframes</p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              Have a wireframe, bring it to us. Depending on the scope and
              requirements, we will develop a full-scale responsive UI-UX. From
              an in-depth analysis, prototyping, to test proof hosting, we will
              do it all.
            </p>
          </div>
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF3} alt="" />
            <p className="Text-vuy">If You Already Have an Existing App</p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              Come to us with your next big idea or MVP, and we will help you
              build it ground up.
            </p>
          </div>
        </div>
        <h1 className="Acomplesh">
          What our proficient designers accomplish in
          <span style={{ color: "#EB6F20" }}> UX/UI design</span>.
        </h1>
        <div className="proficient">
          <div className="profic">
            <img className="Uxdjw" src={UIUX} alt="" />
          </div>
          <div className="profic">
            <h2 className="Texticjh">
              Skilled designers achieve what in{" "}
              <span style={{ color: "#EB6F20" }}>UX/UI design</span>?
            </h2>
            <p className="Textarea918">
              Enhance user satisfaction and engagement with our expert UI/UX
              design services. Craft intuitive interfaces and seamless
              interactions that captivate your audience and elevate your digital
              experiences
            </p>
            <p className="Textarea918">
              Our UI/UX design services focus on creating visually appealing and
              user-friendly interfaces, ensuring an exceptional user experience
              that delights and engages your target audience.
            </p>
            <button className="Ubtn" onClick={GetInTouch}>
              Get In Touch
            </button>
          </div>
        </div>
      </div>
      <Portfolio />
      <Testimonials />
      <Quate />
      <Footer />
    </>
  );
}

export default UiUX;
