import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "intersection-observer";
import "./Counter.css";

function Counter() {
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            $(".counter").each(function () {
              var $this = $(this),
                countTo = $this.attr("data-count");
              $({ countNum: $this.text() }).animate(
                {
                  countNum: countTo,
                },
                {
                  duration: 8000,
                  easing: "linear",
                  step: function () {
                    $this.text(Math.floor(this.countNum));
                  },
                  complete: function () {
                    $this.text(this.countNum);
                  },
                }
              );
            });
            observer.unobserve(counterRef.current);
          }
        });
      },
      {
        rootMargin: "0px",
        threshold: 0.5,
      }
    );
    observer.observe(counterRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <>
      <div className="Counter-Main">
        <h2 className="Counter-TEXT">
          Why <span style={{ color: "#EB6F20" }}>Choose Us</span> ?
        </h2>
        <div className="Counter-Data" ref={counterRef}>
          <div className="Sub-Count">
            <div className="Count-pulsh">
              <div className="counter" data-count="10">
                0
              </div>
              <span className="plush">+</span>
            </div>
            <p className="Count-line">Team members</p>
          </div>
          <div className="Sub-Count">
            <div className="Count-pulsh">
              <div className="counter" data-count="105">
                0
              </div>
              <span className="plush">+</span>
            </div>
            <p className="Count-line">Winning Awards </p>
          </div>
          <div className="Sub-Count">
            <div className="Count-pulsh">
              <div className="counter" data-count="10">
                0
              </div>
              <span className="k0">k</span>
              <span className="plush">+</span>
            </div>
            <p className="Count-line">Project Completed</p>
          </div>
          <div className="Sub-Count">
            <div className="Count-pulsh">
              <div className="counter" data-count="810">
                0
              </div>
              <span className="plush">+</span>
            </div>
            <p className="Count-line">Client Reviews</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Counter;
