import React from "react";
import "./Testimonials.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Frame from "./IMG/Frame.svg";
import Avtar from "./IMG/Avtar.png";
import Avtar1 from "./IMG/Avtar1.png";
import Avtar2 from "./IMG/Avtar2.png";
import Avtar3 from "./IMG/Avtar3.png";
import Avtar4 from "./IMG/Avtar4.png";
import Article1 from "./IMG/Article.svg";
import Article2 from "./IMG/Article2.svg";

function Testimonials() {
  const settings2 = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <div className="Testi-main">
        <div className="Testi-img">
          <img className="Freams" src={Frame} alt="" />
        </div>
        <div className="Clint-testi">
          <p className="Testi-text">Testimonials</p>
          <h1 className="Main-texthead">
            Explore glowing reviews from
            {/* <br /> */}
            <span style={{ color: "#EB6F20" }}> our delighted clients.</span>
          </h1>
          {/* Slider */}
          <div className="carousel-container">
            <Slider className="slider2" {...settings2}>
              <div className="Client-slider">
                <div className="Client-Main-Compo">
                  {/* <img className="Avtart" src={Avtar1} alt="" /> */}
                  <div className="Clinet-content">
                    <img className="Articals" src={Article1} alt="" />
                    <p className="Main-Content">
                      Quarec IT Solutions delivered exceptional, customized IT
                      support, aiding our Beauty Salon in efficient appointment
                      and data management for top-notch service.
                    </p>
                    <img className="Articals2" src={Article2} alt="" />
                    <p className="Clinet-name">Beauty Salon, Gujarat</p>
                    <p className="Designation">Founder and MD</p>
                  </div>
                </div>
              </div>
              <div className="Client-slider">
                <div className="Client-Main-Compo">
                  {/* <img className="Avtart" src={Avtar2} alt="" /> */}
                  <div className="Clinet-content">
                    <img className="Articals" src={Article1} alt="" />
                    <p className="Main-Content">
                      Quarec IT: A vital tech partner, their expertise and
                      dedication resolve challenges, implementing efficient,
                      cutting-edge solutions, ensuring robust IT systems.
                    </p>
                    <img className="Articals2" src={Article2} alt="" />
                    <p className="Clinet-name">Global IT company</p>
                    <p className="Designation">Middle East</p>
                  </div>
                </div>
              </div>
              <div className="Client-slider">
                <div className="Client-Main-Compo">
                  {/* <img className="Avtart" src={Avtar3} alt="" /> */}
                  <div className="Clinet-content">
                    <img className="Articals" src={Article1} alt="" />
                    <p className="Main-Content">
                      EnEx Fintech relies on Quarec IT for reliable, scalable
                      solutions in their fast-paced trading environment.
                      Proactive support ensures smooth operation. Highly
                      recommended for trading businesses.
                    </p>
                    <img className="Articals2" src={Article2} alt="" />
                    <p className="Clinet-name">EnEx Fintech</p>
                    <p className="Designation">Trading Expert At Gujarat</p>
                  </div>
                </div>
              </div>
              <div className="Client-slider">
                <div className="Client-Main-Compo">
                  {/* <img className="Avtart" src={Avtar4} alt="" /> */}
                  <div className="Clinet-content">
                    <img className="Articals" src={Article1} alt="" />
                    <p className="Main-Content">
                      Invaluable partner for our recruitment business, ensuring
                      website reliability and cybersecurity expertise. An
                      unmatched IT ally.
                    </p>
                    <img className="Articals2" src={Article2} alt="" />
                    <p className="Clinet-name">Director at Recruitment </p>
                    <p className="Designation">Nigeria</p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          {/* Slider */}
        </div>
      </div>
    </>
  );
}

export default Testimonials;
