import React from "react";
import "./MContact.css";
import Banner from "../AboutUs/Banner/Banner";
import ContactIMG from "./IMG/Rectangle.png";
import ContactForm from "../ContactUs/ContactUs";
import Footer from "../Footer/Footer";

function Contact() {
  return (
    <>
      <Banner BannerName="Get In Touch" BannerImg={ContactIMG} />
      <ContactForm />
      <Footer />
    </>
  );
}

export default Contact;
