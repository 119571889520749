import React, { useEffect } from "react";
import Banner from "../AboutUs/Banner/Banner";
import BImg from "./IMG/Banner.png";
import Main from "./IMG/Normal Banner/WebApp.png";
import "./Services.css";
// GIF
import SGIF1 from "./IMG/Animated GIF/App1.gif";
import SGIF2 from "./IMG/Animated GIF/App2.gif";
import SGIF3 from "./IMG/Animated GIF/App3.gif";
import Line from "./IMG/UIUX/Grupline.svg";
import UIUX from "./IMG/Animated SVG/svg animation web app development.svg";
//Components
import Portfolio from "../Portfolio/Portfolio";
import Testimonials from "../Home/Testimonials/Testimonials";
import Quate from "../AboutUs/Quote/Quote";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

function WebApp() {
  const navigate = useNavigate();
  const GetInTouch = () => {
    navigate("/ContactUs");
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Banner BannerName="Web & App Development " BannerImg={BImg} />
      <div className="Ser-Con">
        <h1 className="Main-write">
          Fostering Online Success with Expert
          <span style={{ color: "#EB6F20" }}> Web/App Development </span>
          Services
        </h1>
        <div className="Stage-Compo">
          <div className="Satge-content">
            <p className="line-stages">
              Transforming Ideas into Digital Realities with Expert Web
              Development Solutions.
            </p>
            <p className="lining-stageing">
              Web and app development are the engines powering innovative
              digital solutions. They entail the art of creating user-friendly,
              high-performance platforms that cater to your specific needs. At
              our web and app development company, we excel in crafting
              cutting-edge solutions that seamlessly blend technology and
              design, delivering exceptional user experiences and achieving your
              digital goals.
            </p>
          </div>
          <div className="Stage-Img">
            <img className="Main-Uiux" src={Main} alt="" />
          </div>
        </div>
        <div className="Cards-main">
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF1} alt="" />
            <p className="Text-vuy">Search Engine optimization </p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              Our strategy is focused on building engaging and consistent
              360-degree brand experiences across all customer touchpoints,
              ensuring that your company stands out in a cluttered marketplace
            </p>
          </div>
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF2} alt="" />
            <p className="Text-vuy">Precision and Innovation Unleashed</p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              Our web development services focus on creating responsive,
              engaging websites that deliver results by combining design,
              functionality, and user experience.
            </p>
          </div>
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF3} alt="" />
            <p className="Text-vuy">On-Demand Software creation</p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              You can choose the greatest options from our extensive resources
              You receive custom software development services of the finest
              caliber at competitive rates
            </p>
          </div>
        </div>
        <h1 className="Acomplesh">
          Innovate, Create, Transform: Your
          <span style={{ color: "#EB6F20" }}> Web and App Development </span>
          Partner.
        </h1>
        <div className="proficient">
          <div className="profic">
            <img className="Uxdjw" src={UIUX} alt="" />
          </div>
          <div className="profic">
            <h2 className="Texticjh">
              Turning ideas into digital reality through expert web and app
              development.
            </h2>
            <p className="Textarea918">
              Boost audience engagement and satisfaction with our SEO and
              marketing mastery. We develop data-driven strategies and
              captivating content that elevate your online reach.
            </p>
            <p className="Textarea918">
              Our SEO and marketing services revolve around maximizing
              visibility and user engagement, ensuring a compelling online
              presence that resonates with and engages your target audience.
            </p>
            <button className="Ubtn" onClick={GetInTouch}>
              Get In Touch
            </button>
          </div>
        </div>
      </div>
      <Portfolio />
      <Testimonials />
      <Quate />
      <Footer />
    </>
  );
}

export default WebApp;
