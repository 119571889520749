import React, { useEffect } from "react";
import Banner from "../AboutUs/Banner/Banner";
import BlogBanner from "./IMG/Rectangle.png";
import "./Blog.css";
import Quote from "../AboutUs/Quote/Quote";
import Footer from "../Footer/Footer";
import { Icon } from "@iconify/react";
import stat3 from "@iconify/icons-material-symbols/stat-3";
import { useNavigate } from "react-router-dom";

function Blog() {
  const navigate = useNavigate();
  const Blog1 = () => {
    navigate("/Blog1");
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Banner BannerName="Blogs" BannerImg={BlogBanner} />
      <h1 className="Blog-Titels">
        Insights from Our <span style={{ color: "#EB6F20" }}>Digital</span>{" "}
        Diary
      </h1>
      <div className="Blog-Main">
        <div className="Card-Blog-mai">
          <div className="Card-Decored"></div>
          <div className="subBlog-o2reanted">
            <p className="Blog-Dates">15/09/2023</p>
            <p className="Text-Conpoblogs">
              IoT value set to accelerate through 2030: Where and how to capture
              it
            </p>
            <p className="blogs-minit">
              New research demonstrates that the Internet of Things (IoT) holds
              substantial economic value, especially within standardized
              production environments. To fully harness this potential,
              companies must not only scale their operations but also invest in
              cutting-edge technologies, cybersecurity measures, and workforce
              training.
            </p>
            <p className="Read-more1" onClick={Blog1}>
              Read more
              <Icon className="ico3" icon={stat3} rotate={1} />
            </p>
          </div>
        </div>
      </div>
      <Quote />
      <Footer />
    </>
  );
}

export default Blog;
