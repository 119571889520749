import React, { useEffect } from "react";
import Banner from "../AboutUs/Banner/Banner";
import BImg from "./IMG/Banner.png";
import Main from "./IMG/Normal Banner/Hardware.png";
import "./Services.css";
// GIF
import SGIF1 from "./IMG/Animated GIF/Hardware1.gif";
import SGIF2 from "./IMG/Animated GIF/Hardware2.gif";
import SGIF3 from "./IMG/Animated GIF/Hardware3.gif";
import Line from "./IMG/UIUX/Grupline.svg";
import UIUX from "./IMG/Animated SVG/svg animation hardware solution.svg";
//Components
import Portfolio from "../Portfolio/Portfolio";
import Testimonials from "../Home/Testimonials/Testimonials";
import Quate from "../AboutUs/Quote/Quote";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

function Hardware() {
  const navigate = useNavigate();
  const GetInTouch = () => {
    navigate("/ContactUs");
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Banner BannerName="Hardware Solutions" BannerImg={BImg} />
      <div className="Ser-Con">
        <h1 className="Main-write">
          Nurturing Innovation with Expert
          <span style={{ color: "#EB6F20" }}> Hardware Solutions</span>
        </h1>
        <div className="Stage-Compo">
          <div className="Satge-content">
            <p className="line-stages">
              Advancing Technology Success with Expert Hardware Solutions
            </p>
            <p className="lining-stageing">
              Hardware solutions are the backbone of technological progress,
              involving cutting-edge innovation in optimizing hardware
              components for peak performance. At our hardware solutions
              company, we specialize in crafting advanced solutions that enhance
              efficiency and reliability, ensuring a lasting impact on your
              technology infrastructure.
            </p>
          </div>
          <div className="Stage-Img">
            <img className="Main-Uiux" src={Main} alt="" />
          </div>
        </div>
        <div className="Cards-main">
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF1} alt="" />
            <p className="Text-vuy">
              customized Manufacturing of hardware products
            </p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              we built according to the exact specifications of the client.
            </p>
          </div>
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF2} alt="" />
            <p className="Text-vuy">Assemble Hardware Equipment</p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              assemble every small essential parts of every high-tech system.
            </p>
          </div>
          <div className="Card-UiUx">
            <img className="Gif-animat" src={SGIF3} alt="" />
            <p className="Text-vuy">
              Providing maintenance for all the products
            </p>
            <img className="Line-img" src={Line} alt="" />
            <p className="Textnow">
              We claim to give you long-term maintenance free system, however
              anything would happen we will always there for you.
            </p>
          </div>
        </div>
        <h1 className="Acomplesh">
          Experience the impact of our
          <span style={{ color: "#EB6F20" }}> hardware development </span>
          expertise firsthand.
        </h1>
        <div className="proficient">
          <div className="profic">
            <img className="Uxdjw" src={UIUX} alt="" />
          </div>
          <div className="profic">
            <h2 className="Texticjh">
              Unleash your brand's possibilities with our
              <span style={{ color: "#EB6F20" }}> hardware solutions</span>.
            </h2>
            <p className="Textarea918">
              Boost audience engagement and satisfaction with our SEO and
              marketing mastery. We develop data-driven strategies and
              captivating content that elevate your online reach.
            </p>
            <p className="Textarea918">
              Our SEO and marketing services revolve around maximizing
              visibility and user engagement, ensuring a compelling online
              presence that resonates with and engages your target audience.
            </p>
            <button className="Ubtn" onClick={GetInTouch}>
              Get In Touch
            </button>
          </div>
        </div>
      </div>
      <Portfolio />
      <Testimonials />
      <Quate />
      <Footer />
    </>
  );
}

export default Hardware;
