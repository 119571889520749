import React from "react";
import "./Steps.css";
import Step1 from "./IMG/Step1.gif";
import Step2 from "./IMG/Step2.gif";
import Step3 from "./IMG/Step3.gif";
import Step4 from "./IMG/Step4.gif";

function WorkingSteps() {
  return (
    <>
      <div className="Main-steps">
        <div className="steps-img-container">
          <div className="steps-img">
            <p className="W-steps">Working Steps</p>
            <h2 className="Easy-steps">
              Some <span style={{ color: "#EB6F20" }}>easy steps</span> to
              process!
            </h2>
            <div className="Steps-box">
              <div className="S-Box">
                <h1 className="Process-no">
                  <img className="Step-gif" src={Step1} alt="" />
                  <span style={{ color: "#FFF" }}>0</span>1
                </h1>
                <p className="P-name">Research</p>
                <p className="P-Content">
                  At Quarec IT Solutions, we begin every project with extensive
                  research to gain a deep understanding of our client's business
                  objectives, target audience, and industry landscape
                </p>
              </div>
              <div className="S-Box">
                <h1 className="Process-no">
                  <img className="Step-gif" src={Step2} alt="" />
                  <span style={{ color: "#FFF" }}>0</span>2
                </h1>
                <p className="P-name">Design</p>
                <p className="P-Content">
                  Our experienced team of designers collaborates closely with
                  clients to create visually captivating and user-friendly
                  interfaces that align with their brand identity and enhance
                  the overall user experience.
                </p>
              </div>
              <div className="S-Box">
                <h1 className="Process-no">
                  <img className="Step-gif" src={Step3} alt="" />
                  <span style={{ color: "#FFF" }}>0</span>3
                </h1>
                <p className="P-name">Develop</p>
                <p className="P-Content">
                   With expertise in various programming languages and
                  frameworks, our skilled developers transform the design
                  concepts into robust and scalable solutions that meet the
                  specific requirements of our clients
                </p>
              </div>
              <div className="S-Box">
                <h1 className="Process-no">
                  <img className="Step-gif" src={Step4} alt="" />
                  <span style={{ color: "#FFF" }}>0</span>4
                </h1>
                <p className="P-name">Test</p>
                <p className="P-Content">
                  Rigorous testing processes ensure the quality and
                  functionality of our solutions. We conduct comprehensive
                  testing to identify and fix any bugs or performance issues,
                  ensuring a seamless experience for end-users.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkingSteps;
