import React, { useState } from "react";
import "./Button.css";
import { useNavigate } from "react-router-dom";

function Button() {
  const [spanPosition, setSpanPosition] = useState({ top: 0, left: 0 });
  const navigate = useNavigate();

  const UIUX = () => {
    navigate("/ContactUs");
  };
  const handleMouseEnter = (e) => {
    const parentOffset = e.currentTarget.getBoundingClientRect();
    const relX = e.clientX - parentOffset.left;
    const relY = e.clientY - parentOffset.top;
    setSpanPosition({ top: relY, left: relX });
  };

  const handleMouseOut = (e) => {
    const parentOffset = e.currentTarget.getBoundingClientRect();
    const relX = e.clientX - parentOffset.left;
    const relY = e.clientY - parentOffset.top;
    setSpanPosition({ top: relY, left: relX });
  };
  return (
    <>
      <div style={{ float: "left" }}>
        <div className="centerer">
          <div className="new-class-name">
            <a
              className="btn-6"
              onClick={UIUX}
              onMouseEnter={handleMouseEnter}
              onMouseOut={handleMouseOut}
            >
              Get In Touch
              <span></span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Button;
